import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
  <Layout>
    <SEO title="Contact Thank's" />

    <section className="subpage-top">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>
              Thank you for your <br /> <span>registration</span>.
            </h2>
            <p>
              We are still improving our website and as soon <br /> as we have
              gone live we will let you know.
            </p>
          </div>
          <div className="col-md-6 text-right">
            <img
              className="img-fluid img-main"
              src={require("../assets/images/global-phone.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section className="subpage-icons">
      <div className="container-fluid">
        <Link to="/" className="btn-custom btn-custom--green">
          Back to home page
        </Link>
      </div>
    </section>
  </Layout>
)

export default ThankYou
